"use client";
import { Inter } from "next/font/google";
import "./globals.css";
import "swiper/css";
import favPng from "@/favicon.png";
import { usePathname, useRouter } from "next/navigation";

const inter = Inter({ subsets: ["latin"] });

const pathMetadata = {
	"/": {
		title: "ZEVO India: India's Electric Mobility Powerhouse",
		description:
			"ZEVO India, the electric mobility powerhouse, offers eco-friendly delivery with a diverse fleet. Discover our commitment to zero emissions.",
	},
	"/about-us": {
		title: "ZEVO India: Electric Vehicle Supply Chain Solutions",
		description:
			"Discover ZEVO India, pioneering the electric vehicle supply chain with a focus on sustainable innovation. Learn about our mission to redefine mobility.",
	},
	"/vehicles": {
		title: "ZEVO Electric Vehicles: Sustainable Deliveries in India",
		description:
			"Explore ZEVO's fleet of electric vehicles tailored for eco-friendly deliveries. From cargo bikes to vans, find the perfect EV solution for your needs.",
	},
	"/driver-partner": {
		title: "Join ZEVO: Earn as a Sustainable Driver Partner",
		description:
			"Drive with ZEVO as a driver partner and contribute to sustainable deliveries. Enjoy flexible hours, competitive earnings, and a supportive environment.",
	},
	"/contact-us": {
		title: "Connect with ZEVO: Your Partner in Sustainable Delivery",
		description:
			"Have questions? Connect with ZEVO for all inquiries on our electric mobility solutions. Reach our team via phone or email today!",
	},
	"/privacy-policy": {
		title: "ZEVO India's Privacy Policy",
		description:
			"Learn how ZEVO India protects your personal information. Understand our data practices and your rights in our comprehensive privacy policy.",
	},
};

export default function RootLayout({ children }) {
	const pathname = usePathname();
	const metadata = pathMetadata[pathname] || {
		title: "ZEVO India - India’s Electric Mobility Powerhouse",
		description: "ZEVO India - Pioneering in Electric Mobility.",
	};
	return (
		<html lang="en">
			<head>
				<title>{metadata.title}</title>
				<meta name="description" content={metadata.description} />
				<link rel="icon" type="image/webp" href={favPng.src}></link>
				<link
					href="https://fonts.googleapis.com/css?family=Montserrat:350,350i,400,400i,500,500i,700,700i"
					rel="stylesheet"
				/>
				<script src="/scripts/google_tag_1.js" />
			</head>
			<body className={inter.className}>
				<noscript>
					<iframe
						src="https://www.googletagmanager.com/ns.html?id=GTM-T4SK7D52"
						height="0"
						width="0"
						style={{ display: "none", visibility: "hidden" }}
					></iframe>
				</noscript>
				{children}
			</body>
		</html>
	);
}
